import {Violation, PaymentMethod} from '@wix/wixstores-graphql-schema-node';
import {ShippingMethodType} from '../../common/constants';
import {ICart} from '../../types/app.types';
import {calcVisibleViolations} from './violations.utils';

const BI_AMOUNT_MULTIPLIER = 100000;
export const toBiAmount = (amount: number) => Math.round(amount * BI_AMOUNT_MULTIPLIER);

export const getCatalogAppIds = (cart: ICart): string => {
  return getMappedUniqueList(cart?.items, ({catalogAppId}) => catalogAppId);
};

export const getItemTypes = (cart: ICart): string => {
  return getMappedUniqueList(cart.items, ({product: {productType}}) => productType);
};

function getMappedUniqueList<T>(arr: T[] | undefined, mapper: (t: T) => string | undefined | null): string {
  return getUniques((arr ?? /* istanbul ignore next */ []).map(mapper).filter(isDefined)).toString();
}

const getUniques = (arr: string[]): string[] => {
  return [...new Set([...arr])];
};

const isDefined = <T>(obj: T): boolean => {
  return !!obj;
};

export const getAdditionalFeesPrice = (cart: ICart): number => {
  return toBiAmount(cart?.totals?.additionalFeesTotal ?? 0);
};

export const getValidations = (
  violations: Violation[],
  supportDeliveryViolationOnCart: boolean
): string | undefined => {
  if (violations?.length > 0) {
    const top3violations = calcVisibleViolations(violations, supportDeliveryViolationOnCart);
    const biViolations = top3violations.map((violation) => {
      return {
        type: violation.severity,
        is_line_item: Boolean(violation.target?.lineItem?.id),
        message: violation.description,
        ...(supportDeliveryViolationOnCart && {
          name: violation.target?.other?.name || violation.target?.lineItem?.name,
        }),
      };
    });

    return JSON.stringify(biViolations);
  }

  return undefined;
};

export const stringifyPaymentMethods = (paymentMethods: PaymentMethod[]): string => {
  return paymentMethods.map((m) => m.name).join(',');
};

export const getNumberOfAdditionalFees = (cart: ICart): number => {
  return cart?.additionalFees?.length ?? 0;
};

export const getItemsCount = (cart: ICart): number => {
  return cart?.items.reduce((count, item) => count + item.quantity, 0) || 0;
};

export const getShippingMethodType = (isPickupFlow: boolean, isNonShippableCart: boolean): ShippingMethodType => {
  const existingShippingMethodType = isPickupFlow ? ShippingMethodType.PICKUP : ShippingMethodType.SHIPPING;
  return isNonShippableCart ? ShippingMethodType.NONE : existingShippingMethodType;
};

export const getOriginalShippingMethod = (cart: ICart): string => {
  const options = cart.shippingRuleInfo?.shippingRule?.options || [];
  const selectedShippingRuleOption = options.find((option) => option.id === cart.selectedShippingOption.id);
  return selectedShippingRuleOption?.title ?? '';
};
