import {NavigationService} from '../services/NavigationService';
import {StyleSettingsService} from '../services/StyleSettingsService';
import {CheckoutNavigationService, ModalType, SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CartService} from '../services/CartService';
import {StoreMetaDataService} from '../services/StoreMetaDataService';
import {CreateCheckoutExceptions, OriginTypes} from '../../common/constants';
import {clickOnCheckoutSfParams} from '@wix/bi-logger-ec-sf/v2/types';
import {OrderService} from '../services/OrderService';
import {MinimumOrderAmountService} from '../services/MinimumOrderAmountService';
import {
  getAdditionalFeesPrice,
  getCatalogAppIds,
  getNumberOfAdditionalFees,
  getOriginalShippingMethod,
  getShippingMethodType,
  getValidations,
} from '../utils/bi.utils';
import {handleCreateCheckoutErrors} from '../utils/handleCreateCheckoutErrors';
import {SPECS} from '../specs';

export class NavigationStore {
  private readonly navigationService: NavigationService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly checkoutNavigationService: CheckoutNavigationService;
  private readonly cartService: CartService;
  private readonly orderService: OrderService;
  private readonly storeMetaDataService: StoreMetaDataService;
  private readonly minimumOrderAmountService: MinimumOrderAmountService;
  private readonly origin: string;

  constructor(
    private readonly siteStore: SiteStore,
    {
      navigationService,
      styleSettingsService,
      checkoutNavigationService,
      cartService,
      storeMetaDataService,
      orderService,
      minimumOrderAmountService,
    }: {
      navigationService: NavigationService;
      styleSettingsService: StyleSettingsService;
      checkoutNavigationService: CheckoutNavigationService;
      cartService: CartService;
      storeMetaDataService: StoreMetaDataService;
      orderService: OrderService;
      minimumOrderAmountService: MinimumOrderAmountService;
    },
    {origin}: {origin: string}
  ) {
    this.navigationService = navigationService;
    this.styleSettingsService = styleSettingsService;
    this.cartService = cartService;
    this.orderService = orderService;
    this.checkoutNavigationService = checkoutNavigationService;
    this.storeMetaDataService = storeMetaDataService;
    this.minimumOrderAmountService = minimumOrderAmountService;
    this.origin = origin;
  }

  private readonly navigateToCheckoutInternal = async ({
    accessibilityEnabled,
  }: {
    accessibilityEnabled: boolean;
  }): Promise<void> => {
    if (!this.siteStore.experiments.enabled(SPECS.SendTrackEventWhenCheckoutLoad)) {
      this.cartService.trackInitiateCheckout();
    }

    const {hasCreatedPaymentMethods, activePaymentMethods, canStoreShip, isPremium} =
      await this.storeMetaDataService.get();

    const isPickupFlow = this.orderService.isPickup;

    const hasTimeSlots = this.orderService.hasTimeSlots;

    const checkIsAllowedToCheckoutParams = {
      hasShippableItems: this.cartService.hasShippableItems,
      isPremium,
      canStoreShip,
      hasCreatedPaymentMethods,
      canShipToDestination:
        this.orderService.canShip ||
        this.cartService.isFullAddressRequired ||
        !this.styleSettingsService.shouldShowShipping,
    };

    const {canCheckout, modalType, shouldNotify} = this.checkoutNavigationService.checkIsAllowedToCheckout({
      ...checkIsAllowedToCheckoutParams,
      fullPaymentOffline: this.cartService.cart.totals.payNow === 0,
    });

    const BIData: clickOnCheckoutSfParams = {
      origin: this.origin,
      cartId: this.cartService.cart.cartId,
      itemsCount: this.cartService.itemsCount,
      cartType: this.cartService.cartType,
      productsList: JSON.stringify(
        this.cartService.cart.items.map((item) => ({id: item.product.id, quantity: item.quantity}))
      ),
      num_of_paymet_providers: activePaymentMethods.length,
      is_with_ewallet_payment: await this.storeMetaDataService.hasEWalletPaymentMethods(),
      is_with_offline_payment: await this.storeMetaDataService.hasOfflinePaymentMethods(),
      paymet_providers: (await this.storeMetaDataService.getPaymentMethodsNames()).join(','),
      orig_shipping_method: getOriginalShippingMethod(this.cartService.cart),
      shippingMethodType: getShippingMethodType(isPickupFlow, this.cartService.isNonShippableCart),
      additionalFeesPrice: getAdditionalFeesPrice(this.cartService.cart),
      numberOfAdditionalFees: getNumberOfAdditionalFees(this.cartService.cart),
      num_of_shipping: this.cartService.cart.shippingRuleInfo?.shippingRule?.options?.length ?? 0,
    };

    if (!canCheckout) {
      if (shouldNotify) {
        this.checkoutNavigationService.sendLostBusinessEmail(isPremium);
      }
      const response = await this.checkoutNavigationService.openModalByType(
        modalType,
        this.styleSettingsService.isEditorX,
        this.cartService.cart
      );

      const shouldProceedToCheckout = modalType === ModalType.UpgradeToPremium && response?.proceed;
      if (!shouldProceedToCheckout) {
        return;
      }
    }

    const deviceType = this.siteStore.isDesktop() ? 'desktop' : /* istanbul ignore next */ 'mobile';

    void this.siteStore.platformBiLogger.clickOnCheckout({
      ...BIData,
      checkoutId: this.cartService.cart.checkoutId,
      ecomId: this.cartService.cart.ecomId,
      purchaseFlowId: this.cartService.cart.purchaseFlowId,
      origin: this.origin,
      catalogAppId: getCatalogAppIds(this.cartService.cart),
      is_member: !!this.siteStore.usersApi.currentUser.loggedIn,
      validationMessage: getValidations(
        this.cartService.cart?.violations,
        this.siteStore.experiments.enabled(SPECS.SupportDeliveryViolationOnCart)
      ),
    });

    return this.checkoutNavigationService.navigateToCheckout({
      a11y: accessibilityEnabled,
      cartId: this.cartService.cart.cartId,
      locale: this.siteStore.locale,
      deviceType,
      originType: OriginTypes.AddToCart,
      siteBaseUrl: this.siteStore.location.baseUrl,
      ...{isPreselectedFlow: isPickupFlow || hasTimeSlots},
      ...(this.cartService.checkoutId ? {checkoutId: this.cartService.checkoutId} : {}),
    });
  };

  private readonly navigateToCheckout = async ({accessibilityEnabled}: {accessibilityEnabled: boolean}) => {
    this.navigationService.isNavigationToCheckoutInProcess = true;
    const createCheckoutResult = await this.cartService.createCheckout();

    if (typeof createCheckoutResult !== 'string' && createCheckoutResult?.error) {
      await handleCreateCheckoutErrors(
        this.checkoutNavigationService,
        this.styleSettingsService.isEditorX,
        this.cartService.cart,
        createCheckoutResult.error as CreateCheckoutExceptions
      );
    } else {
      await this.navigateToCheckoutInternal({accessibilityEnabled});
    }

    this.navigationService.isNavigationToCheckoutInProcess = false;
  };

  private get isCheckoutButtonDisabled() {
    return (
      this.navigationService.isNavigationToCheckoutInProcess ||
      !this.cartService.areAllItemsInStock ||
      this.minimumOrderAmountService.shouldDisableCheckout ||
      this.cartService.hasErrorViolations
    );
  }

  public async toProps() {
    return {
      isCheckoutButtonDisabled: this.isCheckoutButtonDisabled,
      locale: this.siteStore.locale,
      continueShopping: this.navigationService.continueShopping,
      continueShoppingHref: await this.navigationService.getContinueShoppingHref(),
      navigateToProduct: this.navigationService.navigateToProduct,
      navigateToFullProductUrl: this.navigationService.navigateToFullProductUrl,
      shouldRenderContinueShopping: this.styleSettingsService.shouldRenderContinueShopping,
      navigateToCheckout: this.navigateToCheckout,
      navigateToCart: this.navigationService.navigateToCart,
      closeSideCart: this.navigationService.closeSideCart,
      isNavigationToCheckoutInProcess: this.navigationService.isNavigationToCheckoutInProcess,
    };
  }
}
