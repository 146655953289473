import {NameInOther, Severity, Violation} from '@wix/wixstores-graphql-schema-node';

export const calcTopThreeViolations = (
  violations: Violation[],
  supportDeliveryViolationOnCart: boolean
): Violation[] => {
  const shouldDisplayViolation = (violation: Violation): boolean => {
    const violationName = violation.target?.other?.name;
    return (
      violationName === NameInOther.OTHER_DEFAULT ||
      (supportDeliveryViolationOnCart && violationName === NameInOther.DELIVERY)
    );
  };

  return violations?.length > 0
    ? [
        ...violations.filter((violation) => violation.severity === Severity.ERROR && shouldDisplayViolation(violation)),
        ...violations.filter(
          (violation) => violation.severity === Severity.WARNING && shouldDisplayViolation(violation)
        ),
      ]
        .slice(0, 3)
        .reverse()
    : [];
};

export const calcVisibleViolations = (
  violations: Violation[],
  supportDeliveryViolationOnCart: boolean
): Violation[] => {
  const top3violations = calcTopThreeViolations(violations, supportDeliveryViolationOnCart);
  return [
    ...top3violations,
    ...violations.filter(
      (violation) => violation.severity === Severity.ERROR && Boolean(violation.target?.lineItem?.id)
    ),
    ...violations.filter(
      (violation) => violation.severity === Severity.WARNING && Boolean(violation.target?.lineItem?.id)
    ),
  ];
};
